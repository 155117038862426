var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajout d'une nouvelle filière"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées du frais scolaire ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Année Univ","label-for":"Année Univ"}},[_c('validation-provider',{attrs:{"name":"Année Univ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":"","placeholder":"Année Univ"},model:{value:(_vm.schoolFees.academic_year),callback:function ($$v) {_vm.$set(_vm.schoolFees, "academic_year", $$v)},expression:"schoolFees.academic_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Parcour","label-for":"Parcour"}},[_c('validation-provider',{attrs:{"name":"Parcour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.parcours,"label":"title","placeholder":"Parcour"},model:{value:(_vm.schoolFees.parcour),callback:function ($$v) {_vm.$set(_vm.schoolFees, "parcour", $$v)},expression:"schoolFees.parcour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Niveau","label-for":"Niveau"}},[_c('validation-provider',{attrs:{"name":"Niveau","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Niveau"},model:{value:(_vm.schoolFees.level),callback:function ($$v) {_vm.$set(_vm.schoolFees, "level", $$v)},expression:"schoolFees.level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"frais inscri","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',[_vm._v("Frais Inscri")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Frais Inscri"},model:{value:(_vm.schoolFees.inscription_costs),callback:function ($$v) {_vm.$set(_vm.schoolFees, "inscription_costs", $$v)},expression:"schoolFees.inscription_costs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"droit scolarite","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',[_vm._v("Droit Scolarité")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Droit Scolarité"},model:{value:(_vm.schoolFees.school_fees),callback:function ($$v) {_vm.$set(_vm.schoolFees, "school_fees", $$v)},expression:"schoolFees.school_fees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"TVA","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',[_vm._v("TVA")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"TVA"},model:{value:(_vm.schoolFees.vat),callback:function ($$v) {_vm.$set(_vm.schoolFees, "vat", $$v)},expression:"schoolFees.vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addSchoolFees.apply(null, arguments)}}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }