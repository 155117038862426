<template>
  <b-card-code title="Ajout d'une nouvelle filière">
    <b-card-text>
      <span>Veuillez saisir les coordonnées du frais scolaire </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Année Univ"
              label-for="Année Univ"
            >
              <validation-provider
                #default="{ errors }"
                name="Année Univ"
                rules="required"
              >
                <b-form-input
                  v-model="schoolFees.academic_year"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="Année Univ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Parcour"
              label-for="Parcour"
            >
              <validation-provider
                #default="{ errors }"
                name="Parcour"
                rules="required"
              >
                <v-select
                  v-model="schoolFees.parcour"
                  :clearable="false"
                  :options="parcours"
                  label="title"
                  placeholder="Parcour"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Niveau"
              label-for="Niveau"
            >
              <validation-provider
                #default="{ errors }"
                name="Niveau"
                rules="required"
              >
                <b-form-input
                  v-model="schoolFees.level"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Niveau"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="frais inscri"
                rules="required|positive"
              >
                <h5>Frais Inscri</h5>
                <b-form-input
                  v-model="schoolFees.inscription_costs"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Frais Inscri"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="droit scolarite"
                rules="required|positive"
              >
                <h5>Droit Scolarité</h5>
                <b-form-input
                  v-model="schoolFees.school_fees"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Droit Scolarité"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="TVA"
                rules="required|positive"
              >
                <h5>TVA</h5>
                <b-form-input
                  v-model="schoolFees.vat"
                  :state="errors.length > 0 ? false:null"
                  placeholder="TVA"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addSchoolFees"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'
import storeAuth from '@/store/store'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,

  },
  data() {
    return {
      password: '',

      schoolFees: {
        academic_year_id: '',
        level: '',
        inscription_costs: '',
        school_fees: '',
        vat: '',
        parcour: '',
      },
      required,
      parcours: [],
    }
  },
  created() {
    this.getParcours()
    this.schoolFees.academic_year = storeAuth.state.currentAcademicYear.academic_year
    this.schoolFees.academic_year_id = storeAuth.state.currentAcademicYear.id
  },
  methods: {
    async getParcours() {
      this.load = 'true'
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
      this.load = 'false'
    },
    async addSchoolFees() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/school-fees/create/',
                {
                  level: this.schoolFees.level,
                  inscription_costs: this.schoolFees.inscription_costs,
                  school_fees: this.schoolFees.school_fees,
                  vat: this.schoolFees.vat,
                  parcour: this.schoolFees.parcour.id,
                  academic_year: this.schoolFees.academic_year_id,
                },
              )
            this.$router.push('/frais-scolaire')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'frais scolaire ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
